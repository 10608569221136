<template>
  <div id="landing-page-payment-component">
    <b-card class="main-container mt-4 remove-padding">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="m-0 text-bold text-primary--landing-page">{{ $t('SideBarItems.subscriptions.text') }}</h4>
        <language landing-page/>
      </div>
      <div v-if="is_payment === 'payment'">
        <div v-if="getPaymentMethods.length > 0" id="tab-payment" class="mt-4">
          <overlay-page :visible="is_loading" :content_wait="is_loading" :rounded="false">
            <b-card id="main-bank-card">
              <div>
                <div class="d-flex" v-if="getPaymentMethods.includes('offline_payment')">
                  <h4 class="text-left">
                    {{ $t('subscription.checkout.bank_method') }}
                  </h4>
                  <div class="text-danger ml-2 mt-1">
                    <small><sup>*</sup>{{ $t('subscription.checkout.select_bank_account') }}</small>
                  </div>
                </div>
                <div id="bank-list" class="mt-4">
                  <div v-if="!is_loading_list">
                    <b-row v-if="getPaymentMethods.includes('offline_payment')">
                      <b-col sm="12" md="4" lg="3" xl="3" class="bank-card m-3"
                             :class="{'bank-card-active': bank_account_id === item._id}"
                             v-for="(item, index) in bank_account_list"
                             @click="selectBankAccount(item._id, 'offline_payment')"
                             :key="index">
                        <div style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                          <b-row>
                            <b-col cols="3">
                              <div :class="item?.logo ? 'bank-img' : 'bank-img-empty'">
                                <img :src="item?.logo" alt="bank-logo">
                              </div>
                            </b-col>
                            <b-col class="text-left">
                              <b-col>
                                <h6 class="mt-1">{{ item.bank_name }}</h6>
                              </b-col>
                              <b-col>
                                <div class="mt-1">{{ item.bank_account_number }}</div>
                              </b-col>
                              <b-col>
                                <div class="mt-1">{{ item.account_name }}</div>
                              </b-col>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                    <div class="mt-4"
                         v-if="getPaymentMethods.includes('qrcode')">
                      <div class="d-flex">
                        <h4 class="text-left">
                          {{ $t('subscription.checkout.prompt_pay') }}
                        </h4>
                      </div>
                      <b-row>
                        <b-col sm="12" md="4" lg="3" xl="3" class="bank-card m-3"
                               :class="{'bank-card-active': bank_account_id === 'qrcode'}"
                               @click="selectBankAccount('qrcode', 'qrcode')">
                          <div style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                            <b-row align-v="center">
                              <b-col cols="3">
                                <div :class="qr_bank_logo ? 'bank-img' : 'bank-img-empty'">
                                  <img :src="qr_bank_logo" alt="bank-logo">
                                </div>
                              </b-col>
                              <b-col>
                                <h6 class="text-left mt-2">
                                  {{ $t('subscription.checkout.pay_with_prompt_pay') }}
                                </h6>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="mt-4"
                         v-if="getPaymentMethods.includes('2c2p')">
                      <div class="d-flex">
                        <h4 class="text-left">
                          {{ $t('subscription.checkout.credit_card.pay_with_credit_card') }}
                        </h4>
                      </div>
                      <b-row>
                        <b-col sm="12" md="4" lg="3" xl="3" class="bank-card m-3"
                               v-for="credit_card in payment_2c2p_list"
                               :key="credit_card._id"
                               :class="{'bank-card-active': bank_account_id === credit_card.account_id}"
                               @click="selectBankAccount(credit_card.account_id, '2c2p')">
                          <div style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                            <div v-if="credit_card.account_no === '01'">
                              <h4>
                                Credit / Debit Card
                              </h4>
                              <div>
                                <b-row class="justify-content-center text-center">
                                  <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                                    <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                                      <img :src="icon" alt="bank-logo">
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                            <div v-if="credit_card.account_no === '02'">
                              <h4>
                                E-wallet / Digital Payment
                              </h4>
                              <div>
                                <b-row class="justify-content-center text-center">
                                  <b-col cols="auto" v-for="(icon, index) in credit_card.icons" :key="index">
                                    <div :class="icon ? 'credit-card-img' : 'bank-img-empty'">
                                      <img :src="icon" alt="bank-logo">
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>

                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div v-else-if="!!is_loading_list" class="text-center text-primary mt-5"
                       style="position: relative;transform: translate(-50%, -50%); left: 50%;top: 50%;">
                    <b-spinner class="align-middle mr-2"/>
                    <strong> {{ $t('loading') }}</strong>
                  </div>
                  <div v-else>
                    <empty-table content="empty" is-empty-in-full-page/>
                  </div>
                  <div>
                    <div id="footer-form" class="mt-4 mb-2">
                      <div class="d-flex justify-content-between">
                        <div>
                          <b-button class="button-wizard" variant="outline" @click="backToPaymentDetail">
                            <i class="fas fa-angle-left"/>
                            {{ $t('common.back') }}
                          </b-button>
                        </div>
                        <div>
                          <b-button class="button-wizard"
                                    variant="primary"
                                    @click="confirmCreateSubscription(bank_type)"
                                    :disabled="is_loading || bank_account_id === '' || bank_account_id === null">
                            {{ $t('common.confirm') }}
                            <b-spinner small v-if="is_loading"/>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </overlay-page>
        </div>
        <div v-else>
          <b-card>
            <div class="text-center p-5">
              <div>
                <img src="@/assets/images/icon/exclamation 1.svg" alt="warnning"/>
              </div>
              <div class="mt-4">
                <h3>{{ $t('subscriptions.no_payment_channel') }}</h3>
                <div>
                  {{ $t('subscriptions.contact_to_admin') }}
                </div>
              </div>
              <div class="d-flex justify-content-center mt-4">
                <b-button class="button-wizard" variant="outline" @click="actionStep('prev', 1)">
                  <i class="fas fa-angle-left"/>
                  {{ $t('common.back') }}
                </b-button>
                <b-button class="button-wizard ml-2" variant="primary" @click="contactUs()">
                  {{ $t('SideBarItems.contactUs.text') }}
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div v-else-if="is_payment === 'invoice'">
        <invoice-subscription :bank-list="bank_account_list"/>
      </div>
      <div v-else-if="is_payment === 'qrcode'">
        <qr-code-payment/>
      </div>
      <div v-else-if="is_payment === '2c2p'">
        <credit :account_id="bank_account_id"/>
      </div>
    </b-card>
    <b-modal id="modal-confirm-qr-code-payment" size="md" centered no-close-on-esc
             no-close-on-backdrop hide-footer>
      <template #modal-header="{ close }">
        <div></div>
        <i class="fas fa-times hover-icon action-button" @click="closePopup()"/>
      </template>
      <h4 class="text-center mt-0 mb-3"> {{ $t('subscription.checkout.qr_code.confirm_payment_qrcode') }}</h4>
      <div class="text-center">
        {{ $t('subscription.checkout.qr_code.exam_confirm_qrcode') }}
      </div>
      <div class="example-img-qr-code">
        <img v-if="$i18n.locale === 'th'" src="@/assets/images/example_qr_th.png" alt="example-qr-code"/>
        <img v-else src="@/assets/images/example_qr_en.png" alt="example-qr-code"/>
      </div>
      <b-row align-h="center" class="mt-4 mb-4">
        <b-col cols="4">
          <b-button
              variant="primary"
              class="w-100"
              :disabled="is_loading_qrcode"
              @click="createSubscriptionPayment('qrcode')"
          >
            {{ $t('button.confirm') }}
            <b-spinner small v-if="is_loading_qrcode"/>
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
              class="w-100 btn-cancel-confirm"
              variant="dark"
              @click="closePopup()"
          >
            {{ $t('common.cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <div class="remark-text">
        <div>
          {{ $t('subscription.checkout.qr_code.remark') }} :
        </div>
        <div class="ml-2" v-html="$t('subscription.checkout.qr_code.remark_content')"/>
      </div>
    </b-modal>
    <b-modal id="modal-confirm-2c2p-payment" size="md" centered no-close-on-esc
             no-close-on-backdrop hide-footer>
      <template #modal-header="{ close }">
        <div></div>
        <i class="fas fa-times hover-icon action-button" @click="closePopup()"/>
      </template>
      <h4 class="text-center mt-0 mb-3"> {{ $t('subscription.checkout.credit_card.confirm_your_credit') }}</h4>
      <div class="text-center">
        {{ $t('subscription.checkout.credit_card.example_confirm_your_credit') }}
      </div>
      <div class="example-img-qr-code">
        <img v-if="$i18n.locale === 'th'" src="@/assets/images/Examplae_Visa_TH.png" alt="example-qr-code"/>
        <img v-else src="@/assets/images/Examplae_Visa_EN.png" alt="example-qr-code"/>
      </div>
      <b-row align-h="center" class="mt-4 mb-4">
        <b-col cols="4">
          <b-button
              variant="primary"
              class="w-100"
              :disabled="is_loading_qrcode"
              @click="createSubscriptionPayment('2c2p')"
          >
            {{ $t('button.confirm') }}
            <b-spinner small v-if="is_loading_qrcode"/>
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
              class="w-100 btn-cancel-confirm"
              variant="dark"
              @click="closePopup()"
          >
            {{ $t('common.cancel') }}
          </b-button>
        </b-col>
      </b-row>
      <div class="remark-text">
        <div>
          {{ $t('subscription.checkout.qr_code.remark') }} :
        </div>
        <div class="ml-2" v-html="$t('subscription.checkout.qr_code.remark_content')"/>
      </div>
    </b-modal>
  </div>
</template>
<script>

import QrCodePayment from "@/views/sellPage/Subscription/qrCode.vue";
import OverlayPage from "@/components/common/overlayPage.vue";
import InvoiceSubscription from "@/views/sellPage/Subscription/invoice.vue";
import Credit from "@/views/sellPage/Subscription/credit.vue";
import EmptyTable from "@/components/common/emptyTable.vue";
import {mapState} from "vuex";
import subscriptionApi from "@/repository/subscriptionApi";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
import settingApi from "@/repository/settingApi";
import Language from "@/components/common/Language.vue";

export default {
  name: 'SellPayment',
  components: {Language, EmptyTable, Credit, InvoiceSubscription, OverlayPage, QrCodePayment},
  data() {
    return {
      data_payment: null,
      tabIndex: 0,
      is_loading_list: false,
      is_loading: false,
      bank_account_list: [],
      bank_account_id: null,
      is_payment: '',
      is_active: null,
      order_detail: {},
      is_loading_qrcode: false,
      channel: null,
      qr_bank_logo: "https://sms2pro-dev.s3.ap-southeast-1.amazonaws.com/public/promptpay.png",
      visa_logo: require('@/assets/images/bank/visa.svg'),
      payment_2c2p_list: [],
      bank_type: '',
      tracking_channel: null
    }
  },
  computed: {
    ...mapState({
      getSettingBrand: state => state.profile.bandSetting,
      getPaymentMethods: state => state.profile.payment_methods
    })
  },
  created() {
    this.order_detail = JSON.parse(sessionStorage.getItem('_order_sell_page'))
    this.is_payment = sessionStorage.getItem('is_payment_sell_page')
    this.is_active = JSON.parse(sessionStorage.getItem('is_active'))
    this.channel = this.getChannel('CHANNEL')
    this.tracking_channel = this.getCookie('TRACKING_CHANNEL')
  },
  mounted() {
    this.getBankAccountList()
    this.getCreditPayment2c2pList()
  },
  methods: {
    backToPaymentDetail(){
      this.$emit('submitPhase', 'detailPhase')
      sessionStorage.removeItem('_order_sell_page')
      sessionStorage.removeItem('is_payment_sell_page')
    },
    actionStep(action) {
      const action_btn = {
        action: action.action,
        page_index: action.page_index || null
      }
      this.bank_account_id = null
      this.$emit('set-active-step', action_btn)
    },
    getBankAccountList() {
      this.is_loading_list = true
      subscriptionApi.getBankAccountList().then(resp => {
        this.bank_account_list = _.filter(resp?.data, item => {
          return item?.status === 1
        })
      }).finally(() => {
        this.is_loading_list = false
      })
    },
    selectBankAccount(data, type) {
      this.bank_account_id = data
      this.bank_type = type
    },
    confirmCreateSubscription(type) {
      if (type === 'offline_payment') {
        this.createSubscriptionPayment(type)
      } else if (type === 'qrcode') {
        this.$bvModal.show('modal-confirm-qr-code-payment')
      } else if (type === '2c2p') {
        this.$bvModal.show('modal-confirm-2c2p-payment')
      }
    },
    createSubscriptionPayment(payment_method) {
      if (this.order_detail.is_custom) {
        const map_data = {
          bank_account_id: payment_method === 'offline_payment' ? this.bank_account_id : null,
          discount_code: this.order_detail.discount_code,
          payment_method: payment_method,
          account_2c2p_id: payment_method === '2c2p' ? this.bank_account_id : null,
          custom_credits: this.order_detail.custom_credits,
          custom_price: this.order_detail.custom_price,
          is_upgrade_url: this.order_detail.is_upgrade_url,
          channel: this.channel,
          tracking_channel: this.tracking_channel,
          is_withholding_tax: this.order_detail?.is_withholding_tax ? this.order_detail?.is_withholding_tax : false
        }
        if (payment_method === 'offline_payment') {
          this.createSubscriptionCustomPackage(map_data)
        } else {
          this.createSubscriptionCustomPackageOtherChannel(map_data)
        }
      } else {
        const map_data = {
          plan_id: this.order_detail.plan_id,
          bank_account_id: payment_method === 'offline_payment' ? this.bank_account_id : null,
          discount_code: this.order_detail.discount_code,
          payment_method: payment_method,
          account_2c2p_id: payment_method === '2c2p' ? this.bank_account_id : null,
          is_upgrade_url: this.order_detail.is_upgrade_url,
          channel: this.channel ? this.channel : null,
          tracking_channel: this.tracking_channel ? this.tracking_channel : null,
          is_withholding_tax:  this.order_detail?.is_withholding_tax ? this.order_detail?.is_withholding_tax : false
        }
        if (payment_method === 'offline_payment') {
          this.createSubscriptionFullPackage(map_data)
        } else {
          this.createSubscriptionFullPackageOtherChannel(map_data)
        }

      }
    },
    createSubscriptionFullPackage(data) {
      this.is_loading = true
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('subscription.checkout.confirm_subscription_package'),
        text: this.$t('subscription.checkout.cannot_be_changed'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.confirm'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          subscriptionApi.createSubscription(data).then(resp => {
            if (!!resp) {
              this.$router.push(`/Sell/Subscriptions?sub_id=${resp?.subscription_id}`).catch(() => {
              }).finally(() => {
                this.is_payment = 'invoice'
                sessionStorage.setItem('is_payment_sell_page', this.is_payment)
              })
            }
          }).catch(err => {
            if (err?.response?.status !== 401) {
              alert.addNewFailed(this, err.response?.data?.message)
            }
          }).finally(() => {
            this.is_loading = false
          })
        } else {
          this.is_loading = false
        }
      })
    },
    createSubscriptionCustomPackage(data) {
      this.is_loading = true
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('subscription.checkout.confirm_subscription_package'),
        text: this.$t('subscription.checkout.cannot_be_changed'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.confirm'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          subscriptionApi.createSubscriptionCustomPackage(data).then(resp => {
            if (!!resp) {
              this.$router.push(`/Subscriptions/Checkout?sub_id=${resp?.subscription_id}`).catch(() => {
              }).finally(() => {
                this.is_payment = 'invoice'
                sessionStorage.setItem('is_payment_sell_page', this.is_payment)
              })
            }
          }).catch(err => {
            if (err?.response?.status !== 401) {
              alert.addNewFailed(this, err.response?.data?.message)
            }
          }).finally(() => {
            this.is_loading = false
          })
        } else {
          this.is_loading = false
        }
      })
    },
    createSubscriptionFullPackageOtherChannel(data) {
      this.is_loading_qrcode = true
      subscriptionApi.createSubscription(data).then(resp => {
        if (!!resp) {
          this.$router.push(`/Sell/Subscriptions?sub_id=${resp?.subscription_id}`).catch(() => {
          }).finally(() => {
            this.is_payment = data?.payment_method
            sessionStorage.setItem('is_payment_sell_page', this.is_payment)
          })
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.addNewFailed(this, err.response?.data?.message)
        }
      }).finally(() => {
        this.is_loading_qrcode = false
        this.closePopup()
      })
    },
    createSubscriptionCustomPackageOtherChannel(data) {
      this.is_loading_qrcode = true
      subscriptionApi.createSubscriptionCustomPackage(data).then(resp => {
        if (!!resp) {
          this.$router.push(`/Subscriptions/Checkout?sub_id=${resp?.subscription_id}`).catch(() => {
          }).finally(() => {
            this.is_payment = data?.payment_method
            sessionStorage.setItem('is_payment_sell_page', this.is_payment)
          })
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.addNewFailed(this, err.response?.data?.message)
        }
      }).finally(() => {
        this.is_loading_qrcode = false
        this.closePopup()
      })
    },
    closePopup() {
      this.tabIndex = 0
      this.$bvModal.hide('modal-confirm-qr-code-payment')
      this.$bvModal.hide('modal-confirm-2c2p-payment')
    },
    contactUs() {
      this.$router.push('/Contact-Us').catch(() => {
      })
    },
    getChannel(name) {
      return functionsCommon.getCookie(name)
    },
    getCreditPayment2c2pList() {
      settingApi.getPayment2c2pList().then(resp => {
        this.payment_2c2p_list = _.filter(resp?.data, item => {
          return item?.status === 1
        })
      }).catch(err => {
        throw err
      })
    },
    getCookie(c_name) {
      return functionsCommon.getCookie(c_name)
    },
  },
  watch: {
    'tabIndex'(val) {
      if (val === 1) {
        this.$bvModal.show('modal-confirm-qr-code-payment')
      }
    }
  },
}
</script>
<style scoped lang="scss">

#tab-payment::v-deep.tabs {
  text-align: -webkit-center;
}

#tab-payment::v-deep .card-header {
  border-radius: 40px;
  width: fit-content;
  padding: .2rem 1rem .2rem 1rem;
  margin: .5rem;
  background: #FFFFFF;
}

#tab-payment::v-deep .nav-pills a.nav-link.active {
  border-radius: 20px !important;
  margin: .2rem;
}

#tab-payment::v-deep a.nav-link {
  border-radius: 20px !important;
  margin: .2rem;
}

#tab-payment::v-deep .nav-link:hover {
  border-radius: 20px !important;
}

.bank-card {
  background: #FFF;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.10);
  padding: 1rem;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  //min-height: 125px;
  width: 100%;
}

.bank-img-empty {
  background: #000000;
  border-radius: 10px;
}

.bank-img img {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10px;
}

.credit-card-img img {
  position: relative;
  width: auto;
  height: 1rem;
  border-radius: 10px;
  margin: .1rem;
  justify-content: center;
}

.bank-card, .card-box-secondary {
  .bank-img img {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10px;
  }
}

.bank-card-active {
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 1px 20px -12px var(--primary-color)
}

.button-wizard {
  min-width: 7rem;
  max-width: 100%;
}

.card-box-secondary {
  border-radius: 1.25rem;
  background: #F0F0F0;
  padding: 1rem;
  justify-content: center;
  font-weight: 600;
}

.example-img-qr-code {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #e8e8e8;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.example-img-qr-code > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.remark-text {
  color: red;
  font-size: 14px;
  display: flex;
  word-break: break-all;
}

@media (max-width: 768px) {
  .wrapper-bank-card-qr {
    display: grid;
    grid-template-columns: auto;
    background-color: #fff;
    color: #444;
  }
  .wrapper-bank-card {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    background-color: #fff;
    color: #444;
  }
  .tab-content > .tab-pane {
    padding: 0 0 10px !important;
  }
  .remark-text {
    color: red;
    font-size: 12px;
    display: table;
    word-break: break-all;
  }
  #tab-payment::v-deep .card-header {
    border-radius: 40px;
    width: fit-content;
    padding: .1rem .8rem .1rem .8rem;
    margin: 0;
    background: #FFFFFF;
  }
}
</style>