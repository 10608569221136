<template>
  <div id="setting-subscriptions-sell-page" class="container-subscription-sell-page">
    <div class="logo-landing-page">
      <img
          src="@/assets/images/icon/logo-s.svg"
          width="100px"
          alt="logo"
      />
      <img
          src="@/assets/images/icon/sms-2pro-logo.svg"
          width="250px"
          alt="text_logo"
      />
    </div>
    <subscription @submitPhase="setPhase" v-if="payment === 'detailPhase'"/>
    <payment @submitPhase="setPhase"
             v-if="payment === 'paymentPhase'"/>
    <Footer landing-page/>
  </div>
</template>
<script>
import Subscription from "@/views/sellPage/Subscription/Subscription.vue";
import Payment from "@/views/sellPage/Subscription/Payment.vue";
import Footer from "@/layouts/full-layout/footer/Footer.vue";

export default {
  name: 'MainSellSubscription',
  components: {Footer, Subscription, Payment},
  data() {
    return {
      data_payment: null,
      package_id: null,
      sub_id:null,
      payment: '',
      has_no_premium: false
    }
  },
  methods: {
    setPhase(param) {
      if (param) {
        sessionStorage.setItem('paymentDetailPhase', param)
        this.payment = sessionStorage.getItem('paymentDetailPhase') ? sessionStorage.getItem('paymentDetailPhase') : 'detailPhase'
      }
    }
  },
  mounted() {
    this.payment = sessionStorage.getItem('paymentDetailPhase') ? sessionStorage.getItem('paymentDetailPhase') : 'detailPhase'
  },
  beforeMount() {
    sessionStorage.removeItem('token_register')
    sessionStorage.removeItem('package_id')
    sessionStorage.removeItem('setLandingPageRegister')
    sessionStorage.removeItem('premium_url')
  }
}
</script>
<style lang="scss">
#setting-subscriptions-sell-page.container-subscription-sell-page {
  padding: 58px 16%;
  @media (max-width: 576px) {
    padding: 48px 5%;
  }
}

#setting-subscriptions-sell-page {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .logo-landing-page {
    display: flex;
    justify-content: center;
  }

  .landing-text-grey {
    color: #767676;
  }

  .btn-layout {
    .primary-btn-landing-page, .primary-btn-landing-page--out-line, .disable-primary-btn-landing-page, .disable-primary-btn-landing-page--out-line, .danger-btn-landing-page--out-line {
      background: #19CBAC;
      padding: 6px 32px;
      width: auto;
      color: #FFFFFFFF;
      border: 1px solid #19CBAC;
      border-radius: 20px;

      &:hover {
        filter: brightness(95%);
        transition: all ease-out 0.2s;
      }
    }

    .disable-primary-btn-landing-page, .disable-primary-btn-landing-page--out-line {
      background: #BDBDBD !important;
      border-color: transparent !important;
      cursor: not-allowed;
      opacity: 0.65;

      &:hover {
        filter: initial;
        transition: all ease-out 0.2s;
      }
    }


    .primary-btn-landing-page--out-line, .danger-btn-landing-page--out-line {
      background: #FFFFFFFF;
      color: #19CBAC;

      &:hover {
        background: #19CBAC !important;
        border-color: #19CBAC !important;
        color: #FFFFFFFF;
        filter: brightness(110%);
        transition: all ease-out 0.2s;
      }
    }

    .danger-btn-landing-page--out-line {
      background: #FFFFFFFF;
      color: #D30000;
      border: 1px solid #D30000;

      &:hover {
        background: #D30000 !important;
        border-color: #D30000 !important;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;

      .primary-btn-landing-page, .primary-btn-landing-page--out-line, .danger-btn-landing-page--out-line {
        width: 100%;
      }
    }
  }

  .text-primary--landing-page {
    color: #19CBACFF !important;
  }

  .landing-text {
    color: #525252 !important;
  }

  .logo-landing-page {
    display: flex;
    justify-content: center;
  }

  .label-for-input {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 0;
  }

  .remove-padding {
    > .card-body {
      padding: 0;
    }
  }

  .main-container {
    padding: 42px 4%;
    opacity: 0;
    animation: fadeIn 0.4s ease-in-out forwards;

    @media (max-width: 768px) {
      padding: 24px 4%;
    }

    @media (max-width: 576px) {
      padding: 24px 4%;
    }
  }
}
</style>