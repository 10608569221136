<template>
  <div role="group" class="input-component-container-search">
    <div class="form-group mb-0" :style="`min-height: ${minHeight};`">
      <b-row v-if="labelInput">
        <b-col class="col-auto" style="padding-right: 5px!important;">
          <label class="label-for-input text-secondary">{{ labelInput }} </label>
        </b-col>
        <b-col v-show="required" align="left" style="padding-left: 5px!important;">
          <strong v-show="required" style="color: red">*</strong>
          <label style="font-size: 10px" class=" text-danger ml-2" v-if="requireDetail">{{ requireDetail }} </label>
        </b-col>
      </b-row>
      <b-form-input :class="`input-text-box-search ${className} ${error} ${(icon === 'password' ? 'pr-5' : '')} `"
                    :id="inputId"
                    :state="state"
                    :type="isShowPass === false ? type : 'text'"
                    :placeholder="placeholder"
                    :autocomplete="type === 'password' ? 'new-password' : 'off'"
                    :value="value"
                    :style="componentStyle "
                    @click="activeSearch"
                    @input="updateValue"
                    @keypress="keypress"
                    @keyup="keyup"
                    @focusout="focusout"
                    :maxlength="max"
                    :min="minDate"
                    :max="maxDate"
                    :size="size"
                    :disabled="disabledType"
      />
      <b-img v-show="icon==='password'&&isShowPass===false" @click="showPassword" class="icon-show mt-1"
             src="~@/assets/images/icon/Eye-off.svg"/>
      <b-img v-show="icon==='password'&&isShowPass===true" @click="showPassword" class="icon-show-off "
             src="~@/assets/images/icon/Show.svg"/>
      <span v-if="showCount" class="input-count">{{ value.length }}/{{ max }}</span>
    </div>
    <div v-if="searchOptions && showSearch && filterSearchInput.length > 0" class="select-container"
         ref="selectContainer" @click="stopPropagation">
      <div v-for="(list,index) in filterSearchInput"
           :class="{'select-container__item--selected': selectedIndex === list.discount_code}"
           class="select-container__item" @click=" ($event) => selectValue($event, list.discount_code)">
        <div class="justify-content-between d-flex">
          <div class="text-left text-bold">{{ list.discount_code }}</div>
          <div class="text-right text-success">{{ $t('common.is_active') }}
          </div>
        </div>
        <div class="type-promotion">
          {{ $t('common.type') }} : {{ changeNamePromotionType(list) }}
        </div>
      </div>
    </div>
    <div v-if="requireLabel">
      <label style="color: #FC6C6B; margin-bottom: 0;margin-top: 5px">{{ requireLabel }}</label>
    </div>
  </div>
</template>
<script>
import {parseInt} from "lodash";
import functionsCommon from "@/common/functions";

export default {
  name: "inputComponentSearch",
  props: {
    searchOptions: {
      type: [Object, Array, Boolean],
      default: false,
      request: false
    },
    componentStyle: {
      type: String,
      request: false
    },
    showCount: {
      type: Boolean,
      request: false,
      default: false
    },
    requireLabel: {
      type: String,
      request: false
    },
    requireDetail: {
      type: String,
      request: false
    },
    max: {
      type: String,
      request: false
    },
    className: {
      type: String,
      request: false
    },
    error: {
      type: String,
      request: false
    },
    type: {
      type: String,
      request: false
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      request: false
    },
    inputId: {
      type: String,
      request: false
    },
    labelInput: {
      type: String,
      request: false
    },
    disabledType: {
      type: Boolean,
      request: false,
      default: false
    },
    bgColor: {
      type: String,
      request: false
    },
    value: {
      type: [String, Number, Boolean],
      request: false,
      default: null
    },
    icon: {
      type: String,
      request: false
    },
    required: {
      type: Boolean,
      request: false,
      default: false
    },
    minDate: {
      type: [Date, String, Number],
      require: false
    },
    maxDate: {
      type: [Date, String, Number],
      require: false
    },
    minHeight: {
      type: String,
      request: false,
      default: '3rem'
    },
    size: {
      type: String,
      request: false,
      default: ''
    }
  },
  data() {
    return {
      showDiv: true,
      showSearch: false,
      formatted: '',
      selected: '',
      isShowPass: false,
      selectedIndex: null,
    }
  },
  mounted() {
    // Add a click event listener to the document to close the div when clicking outside of it
    document.addEventListener('click', this.closeDivOnClickOutside);
  },
  beforeDestroy() {
    // Remove the click event listener when the component is destroyed
    document.removeEventListener('click', this.closeDivOnClickOutside);
  },
  computed: {
    filterSearchInput() {
      if (this.value?.length > 0) {
        return this.searchOptions.filter(option =>
            option.discount_code.includes(this.value)
        )
      } else {
        return this.searchOptions.filter(option =>
            option.discount_code
        )
      }
    }
  },
  methods: {
    selectValue(_, element) {
      this.selectedIndex = element;
      this.$emit('input', element)
    },
    stopPropagation(event) {
      // Prevent the click event from propagating up to the document
      event.stopPropagation();
      this.showSearch = false;
    },
    closeDivOnClickOutside(event) {
      if (this.showSearch && !this.$refs?.selectContainer?.contains(event?.target) && this.searchOptions) {
        this.showSearch = false
      }
    },
    activeSearch(event) {
      if (this.searchOptions) {
        this.showSearch = true
        event.stopPropagation();
      }
    },
    updateValue(e) {
      const new_data = e.trim()
      const data = (this.type === 'number') && this.step !== 'any' ? parseInt(new_data) : new_data
      this.$emit('input', data)
    },
    showPassword() {
      this.isShowPass = !this.isShowPass
    },
    deleteEmoji(data) {
      return functionsCommon.removeEmoji(data)
    },
    keypress(event) {
      this.$emit('keypress', event)
    },
    keyup(event) {
      this.$emit('keyup', event)
    },
    focusout(event) {
      this.$emit('focusout', event)
    },
    changeNamePromotionType(item) {
      const discount = item?.discount?.toLocaleString()
      switch (item.type) {
        case 'discount-sms-unit' :
          return `${this.$t('common.add_credit')} (${discount} ${this.$t('common.credit')})`;
        case 'discount-sms-unit-percentage' :
          return `${this.$t('common.credit_percentage')} (${discount}%)`;
        case 'discount-price' :
          return `${this.$t('common.discount_price')} (${discount} ${this.$t('common.baht')})`;
        case 'discount-percentage' :
          return `${this.$t('common.discount_percent')} ( ${discount} %)`;
        default:
          return '-'
      }
    },
  },
  watch: {
    'value'(newVal) {
      if (!_.isNil(newVal) && !_.isEmpty(newVal)) {
        this.$emit('input', this.deleteEmoji(newVal))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-component-container-search {
  position: relative;

  .select-container {
    position: absolute;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #F6F6F6;
    background: white;
    max-height: 11rem;
    overflow: scroll;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .select-container__item {
    cursor: pointer;
    padding: 8px 12px;

    .select-container__item-detail {
      overflow-x: scroll;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &:hover {
      font-weight: 700;
      background: #E8ECF4;
    }
  }

  .select-container__item--selected {
    font-weight: 700;
    background: #E8ECF4; /* Change this to your desired background color */
  }
}


.input-text-box-search {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 8px;
  color: #586A8F !important;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: normal;
}

.form-control {
  font-family: "Prompt";
  font-size: 0.875rem;
  color: #586A8F !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.label-for-input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 0;
}

.icon-show {
  position: absolute;
  right: 1px;
  /*top: 30px;*/
  cursor: pointer;
  padding: 7px;
  min-width: 10px;
  width: 35px;
}

.icon-show-off {
  position: absolute;
  right: 1px;
  /*top: 30px;*/
  cursor: pointer;
  padding: 6px;
  min-width: 10px;
  margin-top: 2px;
}

.input-disable-gray {
  background: #F8F8F8 !important;
  border: 0;
}

.error {
  border: 1px solid #FC6C6B !important;
}

.input-disable-blue {
  background: #EFF6FF !important;
  border: 1px solid #EFF6FF;
}

.input-count {
  position: absolute;
  right: 50px;
  margin: -30px;
  opacity: 0.8;
  font-size: 12px;
  color: #94959B;
}

.form-group {
  position: relative;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-control:focus {
  border-color: #ced4da;
  padding-right: 12px;
  background-image: none;
}

.form-control::-webkit-input-placeholder {
  color: rgba(180, 180, 185, 0.98);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #D5DFE9;
  box-shadow: transparent;
  padding-right: 12px;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
  padding-right: 12px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 12px !important;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 12px !important;
  border: 1px solid var(--danger);
}
</style>