<template>
  <div>
    <overlay-page :visible="loading">
      <b-card class="p-3">
        <div class="d-flex justify-content-between align-items-end custom-header">
          <div class="full-width">
            <h4 class="text-left mb-3">
              {{ $t('subscription.checkout.bank_method') }}
            </h4>
            <div class="card-box-secondary">
              <div class="bank-img">
                <img v-if="bank_detail?.logo" :src="bank_detail?.logo" width="50" height="50" class="mr-3"
                     alt="logo">
              </div>
              <div>
                <p class="m-0 mb-2 d-flex" style="gap: 10px">
                  <span class="bank-account-detail m-0">{{ `${bank_detail.bank_name} :` }}</span>
                  <span class="bank-account-detail-content">{{ bank_detail.bank_account_number }}</span>
                </p>
                <p class="m-0 d-flex" style="gap: 10px">
                  <span class="bank-account-detail">
                    {{ `${$t('subscription.checkout.payment_method')} :` }}
                  </span>
                  <span class="bank-account-detail-content">
                    {{ $t('subscription.checkout.bank_method') }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-end full-width" style="gap: 10px">
            <b-button variant="danger" class="btn-cancel"
                      :class="{'mt-3 w-100 mb-3': innerWidth <= mobileWidth}"
                      :style="{maxWidth:innerWidth > mobileWidth?'200px':''}"
                      @click="showPopup('cancel')"
                      :disabled="is_cancel_loading || form.invoice_status !== 'unpaid'"
            >
              {{ $t('subscription.checkout.cancel_subscription') }}
              <b-spinner small v-if="is_cancel_loading" class="mt-1"/>
            </b-button>
            <div class="d-flex justify-content-between" style="gap: 20px">
              <div>
                <div :class="{'justify-content-start mb-2': innerWidth <= mobileWidth}"
                     class="justify-content-end d-flex">
                  <div class="mr-3"> {{
                      form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
                          $t('invoice.type_receipt') : $t('invoice.type_invoice')
                    }} :
                  </div>
                  <div class="flex-middle" :class="form.is_juristic ? 'type-box  company': ' type-box personal'">
                    {{
                      form.is_juristic ? $t('subscription.checkout.juristic') : $t('subscription.checkout.personal')
                    }}
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex ml-auto justify-content-end">
                  <div class="mr-3">{{ $t('subscription.checkout.status') }}</div>
                  <b-badge pill class="px-2 badge status-box flex-middle"
                           :class="mapStatus(form.invoice_status)">
                    {{ filterValue(optionStatus, form.invoice_status) }}
                  </b-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left mt-5">
          <b-row>
            <b-col sm="12" md="12" lg="6" xl="6">
              <h4 class="text-left">
                {{ $t('subscription.checkout.amount_to_be_transferred') }}
              </h4>
            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
              <h4 class="text-right">
                <button type="button" class="btn waves-effect waves-light btn-outline" @click="showPopup('pdf')">
                  {{
                    form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
                        $t('subscription.checkout.receipt_details') : $t('subscription.checkout.invoice_details')
                  }}
                </button>
              </h4>
            </b-col>
          </b-row>
          <div>
            <b-card class="total-box">
              <div class="mb-2">
                <h4>{{ $t('subscription.checkout.total_amount') }}</h4>
              </div>
              <div>
                <h2 class="text-bold text-info">{{ form.grand_total }}</h2>
              </div>
            </b-card>
          </div>
        </div>
        <div v-if="form.invoice_status !== 'cancelled'">
          <div class="mt-4">
            <div :class="innerWidth <= mobileWidth ? 'text-left' : 'd-flex'">
              <h4>{{ $t('subscription.checkout.upload_transfer_slip') }}</h4>
              <div :class="innerWidth <= mobileWidth ? 'ml-0' : 'ml-2 remark-upload-slip'">
                {{ $t('subscription.checkout.remark_upload_slip') }}
              </div>
            </div>
            <b-row align-h="between">
              <b-col sm="12" md="12" lg="6" xl="6" class="text-left" align-self="center">
                <div class="d-flex">
                  <div class="text-info font-12 mr-2">{{ $t('subscription.checkout.only_support_img_file') }}</div>
                  <div class="text-danger font-12"><sup>*</sup>{{ $t('subscription.checkout.max_file_10_file') }}</div>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6" :class="innerWidth <= innerWidth ? 'mt-3 text-right': 'text-right'">
                <button type="button" class="btn waves-effect waves-light btn-outline" @click="showPopup('slip')">
                  {{ $t('subscription.checkout.sample_slip') }}
                </button>
              </b-col>
            </b-row>
            <div id="upload-slip-subscription">
              <div>
                <upload-image-multiple-component v-model="form.slips"
                                                 @input="setImage"
                                                 accept="image/png, image/jpeg, .pdf"
                                                 :max-file="form.slips.length >= 10"
                                                 :sub-label="$t('common.uploadImg')"
                                                 :close-icon="form.invoice_status === 'pending' || form.invoice_status === 'unpaid'|| form.invoice_status === 'pre-credit-approved'"/>
              </div>
            </div>
            <b-row align-h="between" class="mt-3">
              <b-col class="text-left" align-self="center">
                <div>
                  <h4>{{ $t('subscription.checkout.upload_tax_file') }}</h4>
                  <div class="d-flex">
                    <div class="text-info font-12 mr-2">{{ $t('subscription.checkout.only_support_img_file') }}</div>
                    <div class="text-danger font-12"><sup>*</sup>{{ $t('subscription.checkout.max_file') }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div id="upload-with-holding-tax">
              <upload-file-multiple-component v-model="form.withholding_slips" @input="setImageWithHoldingSlip"
                                              :max-file="form.withholding_slips.length >= 3"
                                              :sub-label="$t('common.uploadTitle')"
                                              accept="image/png, image/jpeg, .pdf"
                                              :close-icon="form.invoice_status === 'pending' || form.invoice_status === 'unpaid'"/>
            </div>
          </div>
          <div class="mt-4 mb-2 text-right">
            <b-button class="button-wizard" variant="primary" :disabled="is_upload_slip || form.slips.length <= 0"
                      @click="uploadSlip">
              {{ $t('subscription.checkout.confirm_upload_slip') }}
              <b-spinner small v-if="is_upload_slip" class="mt-1"/>
            </b-button>
          </div>
        </div>
      </b-card>
    </overlay-page>
    <!--  show modal invoice pdf  -->
    <div>
      <b-modal id="show-invoice-pdf" size="lg" centered no-close-on-esc no-close-on-backdrop hide-footer>
        <template #modal-header="{ close }">
          <h4>{{
              form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
                  $t('subscription.checkout.receipt_details') : $t('subscription.checkout.invoice_details')
            }}</h4>
          <i class="fas fa-times hover-icon action-button" @click="closePopUp('pdf')"/>
        </template>
        <hr class="m-0 p-0"/>
        <div id="invoice">
          <div class="text-detail-invoice mt-2">
            {{ $t('subscription.checkout.detail_download_invoice') }}
          </div>
          <iframe :src="form.pdf_url"
                  type="application/pdf"
                  style="width:100%;height:50vh;border:2px solid #FFFFFF;border-radius: 14px;margin-top: .5rem"
                  scrolling="auto"/>
        </div>
      </b-modal>
    </div>
    <!--  show modal invoice slip  -->
    <div>
      <b-modal id="show-invoice-slip" size="lg" centered no-close-on-esc no-close-on-backdrop hide-footer>
        <template #modal-header="{ close }">
          <h4>{{ $t('subscription.checkout.sample_slip') }}</h4>
          <i class="fas fa-times hover-icon action-button" @click="closePopUp('slip')"/>
        </template>
        <hr class="m-0 p-0"/>
        <div id="invoice">
          <div class="text-detail-invoice mt-2">
            {{ $t('subscription.checkout.detail_upload_slip') }}
          </div>
          <div class="text-center m-3">
            <img src="@/assets/images/slip.png" alt="slip" width="50%"/>
          </div>
        </div>
      </b-modal>
    </div>
    <!--  cancel subscription  -->
    <div>
      <b-modal id="show-invoice-cancel" size="md" hide-footer no-close-on-backdrop no-close-on-esc centered>
        <template #modal-header="{ close }">
          <div></div>
          <i class="fas fa-times hover-icon action-button" @click="closePopUp('cancel')"/>
        </template>
        <div class="mt-0 mb-4 ml-4 mr-4">
          <div class="text-center">
            <h4 class="text-danger">{{ $t('subscription.checkout.reason_for_cancelled') }}</h4>
            <div>
              {{ $t('subscription.checkout.more_then_selected') }}
            </div>
          </div>
          <div class="p-4">
            <b-form-group
                class="ml-5"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-for="option in cancel_reasons_list"
                  v-model="cancel.cancel_reasons"
                  :key="option.value"
                  :value="option.value"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
              >
                {{ $t(option.text) }}
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div>
            <b-button class="w-100 " variant="primary"
                      :disabled="cancel.cancel_reasons.length <= 0 || is_cancel_subscription"
                      @click="createCancelSubscription">
              {{ $t('common.confirm') }}
              <b-spinner small v-if="is_cancel_subscription" class="mt-1"/>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import subscriptionApi from "@/repository/subscriptionApi";
import masterData from "@/common/masterData.json";
import UploadFileMultipleComponent from "@/components/common/uploadFileMultiple.vue";
import UploadImageMultipleComponent from "@/components/common/uploadImgMultiple.vue";
import OverlayPage from "@/components/common/overlayPage.vue";
import functionsCommon from "@/common/functions";
import alert from "@/common/alert";
import {mapState} from "vuex";
import functions from "@/common/functions";

export default {
  name: 'invoice-subscription',
  components: {OverlayPage, UploadImageMultipleComponent, UploadFileMultipleComponent},
  props: ['bankList'],
  data() {
    return {
      form: {
        slips: '',
        pdf_url: '',
        bank_account_id: '',
        withholding_slips: '',
        is_juristic: '',
        invoice_status: '',
        grand_total: 0
      },
      bank_detail: {
        bank_account_id: '',
        account_name: '',
        bank_account_number: '',
        bank_name: '',
        logo: ''
      },
      subscription_id: null,
      optionStatus: masterData?.invoiceStatus,
      loading: false,
      is_upload_slip: false,
      is_cancel_loading: false,
      cancel_reasons_list: [
        {text: 'subscription.checkout.need_to_modify_order', value: 0},
        {text: 'subscription.checkout.need_to_changed_package', value: 1},
        {text: 'subscription.checkout.other_or_change_your_mind', value: 2},
      ],
      cancel: {
        cancel_reasons: []
      },
      is_cancel_subscription: false
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    })
  },
  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
    this.subscription_id = this.$route.query.sub_id
    if (this.subscription_id) {
      this.getSubscriptionByID()
    }
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },
  methods: {
    clearSession() {
      sessionStorage.removeItem('is_payment_sell_page')
      sessionStorage.removeItem('_order_sell_page')
      sessionStorage.setItem('paymentDetailPhase', 'detailPhase')
    },
    handleBackButton() {
      this.clearSession()
      this.$router.push('/Invoice').catch(() => {
      })
    },
    getSubscriptionByID() {
      this.loading = true
      subscriptionApi.getSubscriptionById(this.subscription_id).then(resp => {
        if (!!resp) {
          let plan = _.get(resp, 'plan', {})
          this.form = {
            ...this.form,
            slips: resp?.slips,
            pdf_url: resp?.pdf_url ? resp?.pdf_url + '#zoom=44' : resp?.invoice_url + '#zoom=44',
            bank_account_id: resp?.bank_account_id,
            withholding_slips: resp?.withholding_slips,
            is_juristic: resp?.customer?.is_juristic,
            invoice_status: resp?.invoice_status,
            grand_total: this.changeFormatTotal(plan?.grand_total, plan?.currency_format)
          }
          const bank_detail = this.bankList.filter(item => {
            return item._id === resp?.bank_account_id
          })
          this.bank_detail = {
            ...this.bank_detail,
            bank_account_id: bank_detail[0].bank_account_id,
            account_name: bank_detail[0].account_name,
            bank_account_number: bank_detail[0].bank_account_number,
            bank_name: bank_detail[0].bank_name,
            logo: bank_detail[0].logo,
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    mapStatus(status) {
      return functionsCommon.colorButtonStatusByText(status);
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    setImage(value) {
      this.form.slips = value
    },
    setImageWithHoldingSlip(value) {
      this.form.withholding_slips = value
    },
    uploadSlip() {
      this.is_upload_slip = true
      const data = {
        slips: this.form.slips,
        withholding_slips: this.form.withholding_slips
      }
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('subscription.checkout.confirm_upload_slip'),
        text: this.$t('subscription.checkout.cannot_be_changed'),
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.confirm'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          subscriptionApi.uploadSlipInvoice(data, this.subscription_id).then(resp => {
            if (resp) {
              this.form.slips = resp?.slips
              alert.updateSuccess(this)
              if (this.checkPermission('list', 'action', 'all-invoices')) {
                this.toAllInvoice()
              }
            } else {
              alert.updateFailed(this, resp?.message)
            }
          }).catch(err => {
            alert.updateFailed(this, err.response?.data?.message)
          }).finally(() => {
            this.is_upload_slip = false
          })
        } else {
          this.is_upload_slip = false
        }
      })
    },
    showPopup(type) {
      if (type === 'pdf' && /Android/i.test(navigator.userAgent)) {
        const link = document.createElement("a");
        link.href = this.form.pdf_url;
        link.download = 'example-invoice-file.pdf';
        document.body.appendChild(link);
        const newTab = window.open();
        newTab.location.href = this.form.pdf_url;
        document.body.removeChild(link);
      } else {
        this.$bvModal.show(`show-invoice-${type}`)
      }
    },
    closePopUp(type) {
      this.$bvModal.hide(`show-invoice-${type}`)
      this.cancel.cancel_reasons = []
    },
    createCancelSubscription() {
      const map_data = {
        id: this.subscription_id,
        cancel_reasons: this.cancel.cancel_reasons
      }
      this.is_cancel_subscription = true
      subscriptionApi.cancelSubscriptionById(map_data).then(resp => {
        if (!!resp) {
          this.clearSession()
          this.$router.push('/Invoice').catch(() => {
          })
          this.closePopUp('cancel')
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.cancelFailed(this, err?.response?.data?.message)
        }
      }).finally(() => {
        this.is_cancel_subscription = false
      })
    },
    changeFormatTotal(total, currency) {
      return functionsCommon.addCurrencyToPrice(total, currency)
    },
    toAllInvoice() {
      this.clearSession()
      this.$router.push('/Invoice').catch(() => {
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  },
  watch: {
    '$i18n.locale'(val) {
      if (val) {
        this.getSubscriptionByID()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.full-width, .btn-cancel {
  @media (max-width: 1024px) {
    width: 100%;
    max-width: initial !important;
  }
}

.custom-header {
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.wrapper-bank-card {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 10px;
  background-color: #fff;
  color: #444;
}

.bank-card {
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
  padding: 1.5rem 0.875rem;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
}

.bank-img-empty {
  background: #000000;
  border-radius: 10px;
}

.bank-img img {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10px;
}

.bank-card, .card-box-secondary {
  .bank-img img {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10px;
  }
}

.bank-card-active {
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  padding: 1.5rem 0.875rem;
  cursor: pointer;
  box-shadow: 0px 1px 20px -12px var(--primary-color)
}

.button-wizard {
  min-width: 7rem;
  max-width: 100%;
}

.card-box-secondary {
  display: flex;
  gap: 10px;
  border-radius: 1.25rem;
  background: #F0F0F0;
  padding: 1rem;
  font-weight: 600;
}

.type-box.personal {
  background: #C5EDFF;
  border: 1px solid #3CADDE;
  color: #2F2E2E;
  text-align: center;
}

.type-box.company {
  background: #FFE1C5;
  border: 1px solid #F49300;
  color: #2F2E2E;
  text-align: center;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.bank-account-detail {
  font-size: 15px;
  font-weight: 900;
}

.bank-account-detail-content {
  font-size: 15px;
  font-weight: 500;
}

.total-box {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  margin-top: .5rem;
}

::v-deep .modal-header {
  margin-bottom: 0;
  padding-bottom: 0;
}

.text-detail-invoice {
  color: #008D69;
}

.remark-upload-slip {
  color: #9F9F9F;
  align-self: center;
  margin-bottom: 3px;
  font-size: 12px;
}

@media (max-width: 768px) {

}
</style>