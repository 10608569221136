<template>
  <div>
    <b-card>
      <div class="subscription-order-qr-code">
        <div class="d-flex justify-content-between align-items-end custom-header">
          <div class="full-width">
            <h4 class="text-left mb-3">
              {{ $t('subscription.checkout.qr_code.qr_code')  }}
            </h4>
            <div class="card-box-secondary">
              <div>
                <p class="m-0 mb-2 d-flex" style="gap: 10px">
                  <span class="bank-account-detail m-0">{{ `${$t('subscription.checkout.qr_code.payment_method')} :` }}</span>
                  <span class="bank-account-detail-content">{{ $t('subscription.checkout.qr_code.qr_code') }}</span>
                </p>
                <p class="m-0 d-flex" style="gap: 10px">
                  <span class="bank-account-detail">
                    {{ `${$t('subscription.checkout.qr_code.transaction_ref')} :` }}
                  </span>
                  <span class="bank-account-detail-content">
                    {{ form.reference1 || '-'  }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column align-items-end full-width" style="gap: 10px">
            <b-button variant="danger" class="btn-cancel" :class="{'mt-3 w-100 mb-3': innerWidth <= mobileWidth}"
                      :style="{maxWidth:innerWidth > mobileWidth?'200px':''}"
                      @click="cancelSubscription()"
                      v-if="form.invoice_status === 'unpaid'"
                      :disabled="is_cancel_loading">
              {{ $t('subscription.checkout.cancel_subscription') }}
            </b-button>
            <div class="d-flex justify-content-between custom-status" style="gap: 20px">
              <div>
                <div :class="{'justify-content-start mb-2': innerWidth <= mobileWidth}"
                     class="justify-content-end d-flex">
                  <div class="mr-3">{{ form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
                      $t('invoice.type_receipt') : $t('invoice.type_invoice') }}
                  </div>
                  <div class="flex-middle" :class="form.is_juristic ? 'type-box  company': ' type-box personal'">
                    {{
                      form.is_juristic ? $t('subscription.checkout.juristic') : $t('subscription.checkout.personal')
                    }}
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex ml-auto justify-content-end">
                  <div class="mr-3">{{ $t('subscription.checkout.status') }}</div>
                  <b-badge pill class="px-2 badge status-box flex-middle"
                           :class="mapStatus(form.invoice_status)">
                    {{ filterValue(optionStatus, form.invoice_status) }}
                  </b-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 mb-4">
          <b-card class="qr-code-box" id="sell-landing-page-qr-code-box">
            <template #header>
              <div>
                <img src="@/assets/images/thai-qr-code.png" alt="qr-code">
              </div>
            </template>
            <div class="mb-4 w-100">
              <div v-if="form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-approved'"
                   class="p-3 mt-5 d-flex align-items-center flex-column">
                <div>
                  <img src="@/assets/images/icon/check-mark.svg" alt="check-mark" width="120" height="120">
                </div>
                <div class="text-success text-bold mt-3">
                  {{ $t('subscription.checkout.qr_code.payment_success') }}
                </div>
                <div class="text-content-black mt-2 mb-5">
                  {{ $t('subscription.checkout.qr_code.check_payment_detail') }}
                </div>
              </div>
              <div v-else class="mt-5">
                <overlay-page :visible="is_loading_qrcode" :is_text="false">
                  <div :class="timer === 0 ? 'disable-qrcode' : 'd-flex justify-content-center'">
                    <img id="qrcode-payment" @error="handleErrorImgQrCode" :src="form.qr_code_img"
                         :class="err_qr_code || innerWidth <= mobileWidth ? '':'mb-5'" alt="qr-code-example"
                         :width="innerWidth <= mobileWidth ? 180 : 220"
                         :height="innerWidth <= mobileWidth ? 180 : 217">
                    <div v-if="err_qr_code">
                      <div>{{ $t('subscription.checkout.qr_code.display_error') }}</div>
                      <div>{{ $t('subscription.checkout.qr_code.req_qr_code') }}</div>
                    </div>
                  </div>
                </overlay-page>
                <div v-if="!is_loading_qrcode">
                  <div class="mt-4">
                    <div v-if="timer !== 0" class="d-flex align-items-center flex-column">
                      <div class="text-danger">{{ $t('subscription.checkout.qr_code.time_qrcode1') }} {{ time }}
                        {{ $t('common.minute') }}
                        {{ $t('subscription.checkout.qr_code.time_qrcode2') }} : {{
                          countdown
                        }}
                      </div>
                      <div class="mt-3 text-content-black">{{
                          $t('subscription.checkout.qr_code.remark_time_qrcode')
                        }}
                      </div>
                    </div>
                    <div v-else class="transaction-ref">
                      <b-row>
                        <b-col sm="12" md="12" lg="6" xl="6">
                          <div class="text-left text-danger">
                            {{ $t('subscription.checkout.qr_code.qrcode_exp') }} : {{ countdown }}
                          </div>
                        </b-col>
                        <b-col sm="12" md="12" lg="6" xl="6">
                          <div class="text-right text-content-black">
                            <a href="javascript:void (0)" @click="resendQrCode()">
                              {{ $t('subscription.checkout.qr_code.resend_qrcode') }}
                            </a>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="mt-3 transaction-ref">
                    <b-row>
                      <b-col>
                        <div class="text-left text-content-gray">
                          {{ $t('subscription.checkout.qr_code.transaction_ref') }}
                        </div>
                      </b-col>
                      <b-col class="text-right text-content-black">
                        <div>
                          {{ form.reference1 || '-' }}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col class="text-left text-content-gray">
                        <div>
                          {{ $t('subscription.checkout.qr_code.net_amount') }}
                        </div>
                      </b-col>
                      <b-col class="text-right text-content-black">
                        <div>
                          {{ form.grand_total }}
                        </div>
                      </b-col>
                    </b-row>
                    <div
                        v-if="timer === 0 && (form.invoice_status !== 'paid' && form.invoice_status !== 'pre-credit-paid')">
                      <hr/>
                      <div class="text-left">
                        {{ $t('subscription.checkout.qr_code.confirm_pay') }}
                      </div>
                      <div>
                        <b-button variant="primary" class="w-100 mt-3" @click="checkInQuiry()">
                          {{ $t('subscription.checkout.qr_code.check_payment') }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <h4 class="text-left"> {{ form.invoice_status === 'paid' || form.invoice_status === 'pre-credit-paid' ?
            $t('invoice.receipt'): $t('subscription.checkout.qr_code.invoice') }}</h4>
        <div class="mt-3">
          <a v-if="is_android" :href="form.pdf_url" target="_blank">{{ form.pdf_url }}</a>
          <iframe :src="form.pdf_url"
                  style="width:100%;height:50vh;border:2px solid #FFFFFF;border-radius: 14px;margin-top: .5rem"
                  scrolling="auto"
                  v-else/>
        </div>
        <div class="text-right mt-4">
          <b-button v-if="checkPermission('list', 'action', 'all-invoices')" class="button-wizard button-all-invoice"
                    variant="outline" @click="toAllInvoice">
            {{ $t('subscription.checkout.qr_code.all_invoice') }}
          </b-button>
        </div>
      </div>
    </b-card>
    <div>
      <b-modal id="show-qrcode-cancel" size="md" hide-footer no-close-on-backdrop no-close-on-esc centered>
        <template #modal-header="{ close }">
          <div></div>
          <i class="fas fa-times hover-icon action-button" @click="closePopUp()"/>
        </template>
        <div class="mt-0 mb-4 ml-4 mr-4">
          <div class="text-center">
            <h4 class="text-danger">{{ $t('subscription.checkout.reason_for_cancelled') }}</h4>
            <div>
              {{ $t('subscription.checkout.more_then_selected') }}
            </div>
          </div>
          <div class="p-4">
            <b-form-group
                class="ml-5"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-for="option in cancel_reasons_list"
                  v-model="cancel.cancel_reasons"
                  :key="option.value"
                  :value="option.value"
                  :aria-describedby="ariaDescribedby"
                  name="flavour-3a"
              >
                {{ $t(option.text) }}
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div>
            <b-button class="w-100 " variant="primary"
                      :disabled="cancel.cancel_reasons.length <= 0 || is_cancel_loading"
                      @click="createCancelSubscription">
              {{ $t('common.confirm') }}
              <b-spinner small v-if="is_cancel_loading" class="mt-1"/>
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>

import subscriptionApi from "@/repository/subscriptionApi";
import functionsCommon from "@/common/functions";
import masterData from "@/common/masterData.json";
import OverlayPage from "@/components/common/overlayPage.vue";
import alert from "@/common/alert";
import {mapState} from "vuex";
import authApi from "@/repository/authApi";
import functions from "@/common/functions";

export default {
  name: "qrCodePayment",
  components: {OverlayPage},
  data() {
    return {
      is_android: false,
      form: {
        slips: '',
        pdf_url: '',
        bank_account_id: '',
        is_juristic: '',
        invoice_status: '',
        grand_total: 0,
        qr_code_img: '',
        qr_code_exp: '',
        reference1: '',
        reference2: '',
        transaction_date: '',
      },
      loading: false,
      is_loading_qrcode: false,
      optionStatus: masterData?.invoiceStatus,
      countdown: '00:00',
      timer: 0,
      time: 0,
      is_payment_success: false,
      is_cancel_loading: false,
      cancel_reasons_list: [
        {text: 'subscription.checkout.need_to_modify_order', value: 0},
        {text: 'subscription.checkout.need_to_changed_package', value: 1},
        {text: 'subscription.checkout.other_or_change_your_mind', value: 2},
      ],
      cancel: {
        cancel_reasons: []
      },
      subscription_id: null,
      err_qr_code: false,
    }
  },
  computed: {
    ...mapState({
      confirm_payment: state => state.subscription.confirm_payment,
      time_income: state => state.subscription.time,
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    })
  },
  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
    this.err_qr_code = false
    this.subscription_id = this.$route.query.sub_id
    if (this.subscription_id) {
      this.getSubscriptionByID()
    }
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },
  methods: {
    clearSession() {
      sessionStorage.removeItem('is_payment_sell_page')
      sessionStorage.removeItem('_order_sell_page')
      sessionStorage.setItem('paymentDetailPhase', 'detailPhase')
    },
    handleBackButton() {
      this.clearSession()
      this.$router.push('/Invoice').catch(() => {
      })
    },
    getSubscriptionByID() {
      this.loading = true
      this.is_loading_qrcode = true
      subscriptionApi.getSubscriptionById(this.subscription_id).then(resp => {
        if (!!resp) {
          let plan = _.get(resp, 'plan', {})
          this.form = {
            ...this.form,
            slips: resp?.slips,
            pdf_url: resp?.pdf_url ? resp?.pdf_url + '#zoom=44' : resp?.invoice_url + '#zoom=44',
            is_juristic: resp?.customer?.is_juristic,
            invoice_status: resp?.invoice_status,
            grand_total: this.changeFormatTotal(plan?.grand_total, plan?.currency_format),
            reference1: resp?.ref1,
            reference2: resp?.ref2,
          }
          if (/Android/i.test(navigator.userAgent)) {
            this.is_android = true
          } else {
            this.is_android = false
          }
        }
        return resp
      }).then(resp => {
        if (resp?.invoice_status === 'unpaid' || resp?.invoice_status === 'pre-credit-approved') {
          this.generateQrcode()
        }
      }).catch(err => {
        this.is_loading_qrcode = false
      }).finally(() => {
        this.loading = false
      })
    },
    generateQrcode() {
      subscriptionApi.createQrCodePayment(this.subscription_id).then(resp => {
        if (!!resp) {
          this.time = Math.floor(resp?.expire_time / 60);
          this.timer = resp?.expire_time
          this.form = {
            ...this.form,
            reference1: resp?.ref1,
            reference2: resp?.ref2,
            qr_code_img: functionsCommon.convertBase64ToImage(resp?.data?.qrImage),
            transaction_date: resp?.transaction_date
          }
          this.err_qr_code = false
          this.countdownQrcodeTime()
        }
      }).catch(err => {
        this.handleErrorImgQrCode()
      }).finally(() => {
        this.is_loading_qrcode = false
      })
    },
    mapStatus(status) {
      return functionsCommon.colorButtonStatusByText(status);
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    toAllInvoice() {
      this.clearSession()
      this.$router.push('/Invoice').catch(() => {
      })
    },
    changeFormatTotal(total = 0, currency = '฿') {
      return functionsCommon.addCurrencyToPrice(total, currency)
    },
    countdownQrcodeTime() {
      let interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(interval)
          this.countdown = '00:00'
        } else {
          const minutes = Math.floor(this.timer / 60);
          const seconds = this.timer % 60;
          this.countdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
          this.is_loading_qrcode = false
          this.timer--
        }
      }, 1000)
    },
    resendQrCode() {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('subscriptions.confirm_resend_qr'),
        html: this.$t('subscriptions.confirm_resend_qr_content'),
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
      }).then(result => {
        if (result.isConfirmed) {
          this.timer = 0
          this.is_loading_qrcode = true
          this.generateQrcode()
        }
      })
    },
    checkInQuiry() {
      subscriptionApi.checkInQuiryQrcode(this.subscription_id).then(resp => {
        if (!!resp) {
          this.getSubscriptionByID()
        }
      }).catch(err => {
        alert.fail(this, err?.response?.data?.message)
      })
    },
    cancelSubscription() {
      this.$bvModal.show('show-qrcode-cancel')
    },
    closePopUp() {
      this.$bvModal.hide(`show-qrcode-cancel`)
      this.cancel.cancel_reasons = []
    },
    createCancelSubscription() {
      const map_data = {
        id: this.subscription_id,
        cancel_reasons: this.cancel.cancel_reasons
      }
      this.is_cancel_loading = true
      subscriptionApi.cancelSubscriptionById(map_data).then(resp => {
        if (!!resp) {
          this.clearSession()
          this.$router.push('/Invoice').catch(() => {
          })
          this.closePopUp()
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.cancelFailed(this, err?.response?.data?.message)
        }
      }).finally(() => {
        this.is_cancel_loading = false
      })
    },
    handleErrorImgQrCode(e) {
      const imgElement = document.getElementById('qrcode-payment');
      imgElement.src = require('@/assets/images/icon/no-img.svg');
      this.err_qr_code = true
    },
    getCreditProfile() {
      authApi.getCustomerProfile(true).then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
  },
  watch: {
    'time_income'(newTime) {
      if (newTime && this.form.reference1 === this.confirm_payment.ref1) {
        this.getSubscriptionByID()
        this.getCreditProfile()
      }
    },
    'form.invoice_status'(val) {
      if (val === 'paid' || val === 'pre-credit-paid') {
        this.timer = 0
        setTimeout(()=>{
          this.$router.push('/Invoice').catch(() => {
          })
        },3000)
      }
    },
    '$i18n.locale'(val) {
      if (val) {
        this.getSubscriptionByID()
      }
    }
  }
}
</script>
<style lang="scss">
#sell-landing-page-qr-code-box{
  .card-header,.card-body{
    display: flex;
    justify-content: center;
  }
}
</style>
<style scoped lang="scss">
.full-width, .btn-cancel {
  @media (max-width: 1024px) {
    width: 100%;
    max-width: initial !important;
  }
}

.custom-status{
  @media (max-width: 576px) {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
}

.custom-header {
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.card-box-secondary {
  border-radius: 1.25rem;
  background: #F0F0F0;
  padding: 1rem;
  justify-content: center;
}

.type-box.personal {
  background: #FFF6E8;
  border: 1px solid #FFC107;
}

.type-box.company {
  background: #FFF6E8;
  border: 1px solid #FFC107;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.qr-code-box {
  padding: 0 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  border: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);

  .card-header {
    font-size: 18px;
    padding: .5rem;
    background: #113566 !important;
    border-radius: 10px 10px 0 0 !important;
    font-weight: 800 !important;
    margin: 0 !important;
    width: 100% !important;
    border: 0 !important;
  }
}

.type-box.personal {
  background: #C5EDFF;
  border: 1px solid #3CADDE;
  color: #2F2E2E;
  text-align: center;
}

.type-box.company {
  background: #FFE1C5;
  border: 1px solid #F49300;
  color: #2F2E2E;
  text-align: center;
}

.type-box,
.status-box {
  border-radius: 20px;
  padding: 0 12px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 80px;
  height: 22px;
}

.transaction-ref {
  margin-right: 25%;
  margin-left: 25%;
}

.subscription-order-qr-code {
  padding: 1rem;
}

.text-content-black {
  color: #2F2E2E;
}

.text-content-gray {
  color: #9F9F9F;
}

.disable-qrcode {
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: .2;
}

.button-all-invoice {
  padding-left: 4%;
  padding-right: 4%;
}

@media (max-width: 768px) {
  .subscription-order-qr-code {
    padding: 0;
    font-size: 12px !important;
  }

  .qr-code-box {
    font-size: 12px !important;
  }

  .transaction-ref {
    margin-right: 3%;
    margin-left: 3%;
  }
}

</style>