<template>
  <div id="landing-page-subscription-component">
    <b-card class="main-container mt-4 remove-padding" id="landing-page-subscription-container">
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="m-0 text-bold text-primary--landing-page">{{ $t('subscription.checkout.order_summery') }}</h4>
        <language landing-page/>
      </div>
      <span class="hr-no-premium mt-3 mb-4" v-if="!has_premium"></span>
      <div :class="{'custom-container':!has_premium}">
        <div class="container-subscription" :class="{'container-subscription-custom':!has_premium}">
          <div class="container-subscription__left" v-if="has_premium" :style="{maxWidth:`${computeWidth}px`}">
            <div class="box-upgrade-package">
              <img v-if="$i18n.locale === 'th'" alt="upgrade-package"
                   src="@/assets/images/upgrade/img_Trcking2Pro@2x.webp"
                   :style="{width: innerWidth > mobileWidth ? computeWidth+'px':'100%'}"
                   style="max-width: 650px"
              />
              <img v-else alt="upgrade-package"
                   src="@/assets/images/upgrade/img_Trcking2ProEN.webp"
                   :style="{width: innerWidth > mobileWidth ? computeWidth+'px':'100%'}"
                   style="max-width: 650px"
              />
            </div>
            <div class="mt-3" :class="{'mt-5':innerWidth > mobileWidth}">
              <div class="text-bold text-primary--landing-page">
                {{ $t('subscription.checkout.conditions') }}
              </div>
              <div class="mt-2 landing-text" :class="{'small':innerWidth > mobileWidth}">
                {{ $t('subscription.checkout.conditions_customer') }}
              </div>
              <div class="font-size-12 mt-2 landing-text">
                {{ $t('subscription.checkout.conditions_company') }}
              </div>
            </div>
          </div>
          <div class="container-subscription__right"
               :style="{paddingLeft: has_premium && innerWidth > mobileWidth? '60px':''}">
            <div id="promotion-code">
              <h4 class="text-primary--landing-page">{{ $t('subscription.checkout.code_promotion') }}</h4>
              <div class="mb-2">
                <input-component-search :searchOptions="plan_options" v-model="promotion_code"
                                        :disabled-type="has_used_promotion"
                                        :placeholder="$t('common.select_code')"/>
                <!--                <multiselect v-model="promotion_code" :options="plan_options"-->
                <!--                             :disabled="has_used_promotion"-->
                <!--                             :show-labels="false"-->
                <!--                             :placeholder="$t('common.select_code')"-->
                <!--                             :searchable="true"-->
                <!--                             label="discount_code"-->
                <!--                             track-by="discount_code">-->
                <!--                  <template slot="option" slot-scope="props">-->
                <!--                    <div class="justify-content-between d-flex">-->
                <!--                      <div class="text-left text-bold">{{ props.option.discount_code }}</div>-->
                <!--                      <div class="text-right text-success">{{ $t('common.is_active') }}-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="type-promotion">-->
                <!--                      {{ $t('common.type') }} : {{ changeNamePromotionType(props.option) }}-->
                <!--                    </div>-->
                <!--                  </template>-->
                <!--                  <span slot="noResult">{{ $t('subscription.checkout.could_not_find_promotion') }}</span>-->
                <!--                  <span slot="noOptions">{{ $t('subscription.checkout.no_promotion_in_package') }}</span>-->
                <!--                </multiselect>-->
              </div>
              <div class="d-flex justify-content-end align-items-center btn-layout" style="gap: 8px">
                <button v-if="promotion_code && !has_used_promotion" class="primary-btn-landing-page--out-line"
                        @click="usePromotionCode('reset')">
                  {{
                    $t('common.resetSearch')
                  }}
                </button>
                <button v-if="has_used_promotion" class="danger-btn-landing-page--out-line"
                        @click="usePromotionCode('cancel')">
                  {{ $t('common.cancel') }}
                </button>
                <button :disabled="promotion_code?.length === 0 || promotion_code === null"
                        class="primary-btn-landing-page"
                        v-else
                        :class="{'disable-primary-btn-landing-page':promotion_code?.length === 0 || promotion_code === null}"
                        @click="usePromotionCode">
                  {{ $t('common.active') }}
                  <b-spinner small class="ml-2" v-show="loading_button"/>
                </button>
              </div>
              <div class="relative">
                <div class="spinner-layout" v-if="loading_promotion">
                  <b-spinner class="text-primary--landing-page" lg></b-spinner>
                </div>
                <div class="mt-4 order-summary" :class="{'low-opacity':loading_promotion}">
                  <div class="package-name summary">
                    <p class="m-0">{{ $t('subscription.checkout.package_name') }}</p>
                    <p class="m-0">{{ package_detail.package_name }}</p>
                  </div>
                  <div class="package-price summary" v-if="package_detail?.plan_discount">
                    <div class="d-flex flex-column" style="gap: 4px">
                      <p class="m-0">{{ $t('subscription.checkout.package_price') }}</p>
                      <p class="m-0 landing-text-grey" style="font-size: 12px">
                        {{ $t('subscription.checkout.discount_package') }}</p>
                    </div>
                    <div class="d-flex flex-column" style="gap: 4px">
                      <p class="m-0">{{ changeCurrency(package_detail?.price) || 0 }}</p>
                      <p class="m-0 text-right text-danger" style="font-size: 12px">
                        {{ `- ${changeCurrency(package_detail?.plan_discount) || 0}` }}</p>
                    </div>
                  </div>
                  <div class="package-price summary" v-else>
                    <p class="m-0">{{ $t('subscription.checkout.package_price') }}</p>
                    <p class="m-0">{{ changeCurrency(package_detail?.price) || 0 }}</p>
                  </div>
                  <div class="package-credit-received summary">
                    <p class="m-0">{{ $t('subscription.checkout.credit_received') }}</p>
                    <p class="m-0"> {{
                        package_detail?.received_credit ? `${changeTextToLocaleString(package_detail?.received_credit)}  ${$t('subscription.checkout.credit')}` : 0
                      }}
                    </p>
                  </div>
                  <div class="package-discount summary" v-if="has_used_promotion">
                    <div class="d-flex flex-column w-100" style="gap: 8px">
                      <p class="m-0 font-weight-bold"> {{ $t('subscription.checkout.code_promotion') }} </p>
                      <div class="summary">
                        <p class="m-0 landing-text-grey">
                          <span> {{ $t('subscription.checkout.discount') }} </span>
                          <span> {{
                              package_detail?.promotion_detail?.discount_type === 'price' ? displayDiscount(package_detail.promotion_detail) : ''
                            }} </span>
                        </p>
                        <p class="m-0">{{
                            package_detail?.promotion_detail?.discount_type === 'price' ? checkAmountPromotion(package_detail.promotion_detail) : changeCurrency(0)
                          }} </p>
                      </div>
                      <div class="summary">
                        <p class="m-0 landing-text-grey">
                          <span>  {{ $t('subscription.checkout.add_credit') }} </span>
                          <span> {{
                              package_detail?.promotion_detail?.discount_type === 'credit' ? displayDiscount(package_detail.promotion_detail) : ''
                            }} </span>
                        </p>
                        <p class="m-0">{{
                            package_detail?.promotion_detail?.discount_type === 'credit' ? checkAmountPromotion(package_detail.promotion_detail) : `0  ${$t('subscription.checkout.credit')} `
                          }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="withholding-tax summary" v-if="include_withholding_tax">
                    <p class="m-0">{{ $t('subscription.checkout.with_holding_tax') }}</p>
                    <p class="m-0 text-danger">{{
                        `- ${changeCurrency(package_detail?.total_withholding_tax) || 0}`
                      }}</p>
                  </div>
                  <div class="package-vat summary">
                    <p class="m-0">{{ $t('subscription.checkout.vat') }}</p>
                    <p class="m-0">{{ `+ ${changeCurrency(package_detail?.vat) || 0}` }}</p>
                  </div>
                  <span class="summary-bottom"></span>
                </div>
                <div class="order-summary-total" :class="{'low-opacity':loading_promotion}">
                  <div class="net-amount summary text-success--landing-page text-bold">
                    <p class="m-0">{{ $t('subscription.checkout.net_amount') }}</p>
                    <p class="m-0">{{ changeCurrency(package_detail?.total_price) || 0 }}</p>
                  </div>
                  <div class="credit summary text-success--landing-page text-bold">
                    <p class="m-0">{{ $t('subscription.checkout.net_credit') }}</p>
                    <p class="m-0">
                      {{
                        netCredit(package_detail.received_credit, package_detail.total_credits) || 0
                      }}</p>
                  </div>
                  <div class="tracking-2pro summary text-warning-landing-page text-bold" v-if="!!has_premium">
                    <p class="m-0">Tracking2Pro</p>
                    <p class="m-0">Free</p>
                  </div>
                  <b-checkbox v-model="include_withholding_tax" @change="usePromotionCode('withholding')"
                              :disabled="has_used_promotion" v-if="juristic_type === 'juristic'"
                  >
                    <label class="landing-text-grey">{{ $t('subscription.checkout.with_holding_tax') }}</label>
                  </b-checkbox>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center btn-layout mt-5">
                <button class="primary-btn-landing-page"
                        :disabled="checkSelectPayment"
                        :class="{'disable-primary-btn-landing-page': checkSelectPayment}"
                        @click="selectPayment">
                  {{ $t('common.next') }}
                  <b-spinner small class="ml-2" v-show="false"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>

import Language from "@/components/common/Language.vue";
import {mapState} from "vuex";
import subscriptionApi from "@/repository/subscriptionApi";
import PackageApi from "@/repository/packageApi";
import functionsCommon from "@/common/functions";
import inputComponentSearch from "@/components/common/inputComponentSearch.vue";
import alert from "@/common/alert";

export default {
  name: 'SellSubscription',
  components: {Language, inputComponentSearch},
  data() {
    return {
      package_id: null,
      juristic_type: 'personal',
      loading_button: false,
      loading_promotion: false,
      width: 0,
      promotion_code: null,
      has_premium: true,
      plan_options: [],
      include_withholding_tax: false,
      has_used_promotion: false,
      package_detail: {
        package_name: '',
        plan_discount: 0,
        price: '',
        currency: '',
        received_credit: 0,
        total_discount: 0,
        total_price: 0,
        discount_type: '',
        promotion_detail: null,
        total_withholding_tax: 0,
        total_credits: 0,
        vat: 0
      }
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
      mobileWidth: state => state.theme.mobile_width
    }),
    checkSelectPayment() {
      if (this.has_used_promotion && this.promotion_code?.length > 0 && typeof this.promotion_code === 'string') {
        return !this.has_used_promotion && this.promotion_code?.length !== 0
      } else if (this.promotion_code?.length > 0 && typeof this.promotion_code === 'string') {
        return this.promotion_code?.length !== 0
      } else {
        return false
      }
    },
    checkPromotionCode() {
      return !_.isNil(this.promotion_code) || this.promotion_code?.length !== 0
    },
    computeWidth() {
      if (this.innerWidth > this.mobileWidth) {
        return this.width / 1.8
      } else {
        return this.width
      }
    },
  }
  ,
  mounted() {
    window.addEventListener('popstate', this.handleBackButton);
    this.package_id = this.$route.query?.package_id ? this.$route.query?.package_id : null
    if (!_.isNil(this.package_id)) {
      this.handleResize()
      this.setUpSubscription(this.package_id)
    }
  }
  ,
  created() {
    this.juristic_type = sessionStorage.getItem('juristic_type') ? sessionStorage.getItem('juristic_type') : "personal"
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.package_id = this.$route.query?.package_id ? this.$route.query?.package_id : null
    if (!_.isNil(this.package_id)) {
      this.getPromotionByPlanId();
    } else {
      this.$router.push('/').catch(() => {
      })
    }
  }
  ,
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  }
  ,
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
  ,
  methods: {
    handleBackButton() {
      sessionStorage.removeItem('is_payment_sell_page')
      sessionStorage.removeItem('_order_sell_page')
      sessionStorage.removeItem('paymentDetailPhase')
      this.$router.push('/').catch(() => {
      })
    }
    ,
    selectPayment() {
      let data = {
        plan_id: this.package_id,
        discount_code: this.promotion_code ? this.promotion_code : null,
        custom_credits: this.package_detail.total_credits,
        custom_price: this.package_detail.total_price,
        is_custom: false,
        is_upgrade_url: !!this.has_premium,
        is_withholding_tax: this.include_withholding_tax
      }
      this.$emit('submitPhase', 'paymentPhase')
      sessionStorage.setItem('_order_sell_page', JSON.stringify(data))
      sessionStorage.setItem('is_payment_sell_page', 'payment')
    }
    ,
    netCredit(package_credit, total_credit) {
      const net_credit = total_credit ? total_credit : package_credit
      return this.changeTextToLocaleString(net_credit) + ' ' + this.$t('subscription.checkout.credit')
    }
    ,
    displayDiscount(promotion) {
      if (promotion?.discount_percentage) {
        return '(' + promotion?.discount_percentage + '%' + ')'
      } else if (promotion?.is_premium_url) {
        return '(' + promotion?.upgrade_percent_discount + '%' + ')'
      } else {
        return ''
      }
    }
    ,
    checkAmountPromotion(promotion) {
      if (promotion.discount_type === 'price') {
        return promotion.total_discount > 0 ? '-' + ' ' + this.changeCurrency(promotion.total_discount) : this.changeCurrency(promotion.total_discount)
      } else if (promotion.is_premium_url) {
        return promotion.upgrade_discount ? '-' + ' ' + this.changeCurrency(promotion.upgrade_discount) : this.changeCurrency(0)
      } else {
        const credit = promotion.total_discount > 0 ? '+' + ' ' + this.changeTextToLocaleString(promotion.total_discount) : this.changeTextToLocaleString(promotion.total_discount)
        return credit + ' ' + this.$t('subscription.checkout.credit')
      }
    }
    ,
    setUpSubscription(package_id) {
      this.loading_promotion = true
      PackageApi.getByPackageId(package_id).then((res) => {
        this.has_premium = res?.premium_url_plan_id ? res?.premium_url_plan_id : false
        this.package_detail.package_name = res?.name
        this.package_detail.currency = res?.currency?.format
      }).then((res) => {
        const map_data = {
          is_custom_plan: false,
          plan_id: package_id,
          discount_code: null,
          include_withholding_tax: false,
          is_upgrade_url: !!res?.premium_url_plan_id
        }
        subscriptionApi.usePromotionCode(map_data).then(res => {
          this.package_detail.price = res?.plan_price
          this.package_detail.total_price = res?.total_price
          this.package_detail.promotion_detail = res?.promotion_detail
          this.package_detail.discount_type = res?.promotion_detail?.discount_type
          this.package_detail.total_discount = res?.promotion_detail?.total_discount
          this.package_detail.vat = res?.vat
          this.package_detail.received_credit = res?.plan_credit
          this.package_detail.total_credits = res?.total_credits
          this.package_detail.plan_discount = res?.plan_discount
          this.package_detail.total_withholding_tax = !_.isNil(res?.total_withholding_tax) ? res?.total_withholding_tax : 0
        }).catch((err) => {
          throw  err
        }).finally(() => {
          this.loading_promotion = false
        })
      }).catch((err) => {
        if (err?.response?.status == 400) {
          this.$router.push({path: '/Dashboard'})
        }
      })
    }
    ,
    changeCurrency(number) {
      return functionsCommon.addCurrencyToPrice(number, this.package_detail.currency)
    }
    ,
    changeTextToLocaleString(text) {
      return Number(text)?.toLocaleString()
    }
    ,
    checkPercentPromotion(promotion) {
      if (promotion.is_percentage_discount) {
        return '(' + promotion.discount_percentage + '%' + ')'
      } else if (promotion.is_premium_url) {
        return '(' + promotion.upgrade_percent_discount + '%' + ')'
      }
    }
    ,
    usePromotionCode(param = '') {
      if (this.checkPromotionCode || param === 'withholding' || param === 'cancel' || param === 'reset') {
        this.loading_promotion = true
        let promotion_code = null

        if (param !== 'withholding') {
          promotion_code = this.promotion_code
          this.loading_button = true
          this.has_used_promotion = true
          if (param === 'cancel' || param === 'reset') {
            promotion_code = null
            this.loading_button = false
          }
        }

        if (param === 'cancel') {
          this.has_used_promotion = false
        }

        if (param === 'reset') {
          this.promotion_code = null
          this.has_used_promotion = false
        }

        const map_data = {
          is_custom_plan: false,
          plan_id: this.package_id,
          discount_code: promotion_code,
          include_withholding_tax: this.include_withholding_tax,
          is_upgrade_url: !!this.has_premium
        }
        subscriptionApi.usePromotionCode(map_data).then(res => {
          this.package_detail.price = res?.plan_price
          this.package_detail.total_price = res?.total_price
          this.package_detail.promotion_detail = res?.promotion_detail
          this.package_detail.discount_type = res?.promotion_detail?.discount_type
          this.package_detail.total_discount = res?.promotion_detail?.total_discount
          this.package_detail.vat = res?.vat
          this.package_detail.received_credit = res?.plan_credit
          this.package_detail.total_credits = res?.total_credits
          this.package_detail.plan_discount = res?.plan_discount
          this.package_detail.total_withholding_tax = !_.isNil(res?.total_withholding_tax) ? res?.total_withholding_tax : 0
        }).catch((err) => {
          this.has_used_promotion = false
          alert.usePromotionCodeFailed(this, err.response.data.message)
        }).finally(() => {
          this.loading_promotion = false
          this.loading_button = false
        })
      }
    }
    ,
    handleResize() {
      this.width = document.getElementById('landing-page-subscription-container')?.offsetWidth;
    }
    ,
    changeNamePromotionType(item) {
      const discount = item?.discount?.toLocaleString()
      switch (item.type) {
        case 'discount-sms-unit' :
          return `${this.$t('common.add_credit')} (${discount} ${this.$t('common.credit')})`;
        case 'discount-sms-unit-percentage' :
          return `${this.$t('common.credit_percentage')} (${discount}%)`;
        case 'discount-price' :
          return `${this.$t('common.discount_price')} (${discount} ${this.$t('common.baht')})`;
        case 'discount-percentage' :
          return `${this.$t('common.discount_percent')} ( ${discount} %)`;
        default:
          return '-'
      }
    }
    ,
    getPromotionByPlanId() {
      subscriptionApi.getPromotionByPlanId(this.package_id).then(resp => {
        this.plan_options = _.filter(resp?.data, item => {
          return !!item.is_available && !item?.is_secret
        })
      }).catch(err => {
        throw err
      })
    }
    ,
  }
}
</script>
<style lang="scss">
#landing-page-subscription-component {
  .text-warning-landing-page {
    color: #FE9661 !important;
  }

  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .low-opacity {
    opacity: 0.5;
  }

  .relative {
    position: relative;

    .spinner-layout {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .custom-container {
    display: flex;
    justify-content: center;

    .container-subscription-custom {
      max-width: 650px;
      width: 100%;
    }
  }


  .summary-bottom {
    width: 100%;
    border-bottom: 1px dashed #525252;
  }

  .hr-no-premium {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #E9E9E9;
  }

  .text-success--landing-page {
    color: #008D69 !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #19CBACFF !important;
    background-color: #19CBACFF !important;
  }


  .container-subscription {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 24px;
    }

    .container-subscription__right {
      width: 100%;

      .order-summary-total {
        margin-top: 24px;
      }

      .order-summary, .order-summary-total {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .summary {
          display: flex;
          color: #525252;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }
  }
}
</style>