import service from './index'
import functionsCommon from "@/common/functions";
import moment from "moment";

const resourcePath = '/package'
const resourcePathPlan = '/plan'
export default {
    findInvoiceAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        let from = moment(search?.date_time[0]).format('YYYY-MM-DD') + 'T' + moment(search?.date_time[0]).format('HH:mm:ss') + '.000Z'
        let to = moment(search?.date_time[1]).format('YYYY-MM-DD') + 'T' + moment(search?.date_time[1]).format('HH:mm:ss') + '.999Z'
        const mapData = {
            ...search,
            from,
            to
        }
        delete mapData.date_time
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(mapData))
        const str = queryString.toString()
        return service.apiAuth.get(`/invoice/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getByPackageId(id) {
        return service.apiAuth.get(`${resourcePathPlan}/get-by-id/${id}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    findPackageAll() {
        return service.apiAuth.get(`${resourcePathPlan}/list`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    customPlanDetail(data) {
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePathPlan}/custom-plan-detail?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    finePlanDetail(planId) {
        return service.apiAuth.get(`${resourcePath}/${planId}`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getActiveCustomPlan() {
        return service.apiAuth.get(`/setting/get-active-custom-plan`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    getPublicList() {
        // DEV
        // group_id: 640ea37e603b53ad60dca710
        // brand_id: 640ea3fa603b53ad60dca75c

        // PRODUCTION
        // group_id: 6412b5e3131bada6c18c2b6e
        // brand_id: 6413f1914e55f51a812c580c
        const group_id = "6412b5e3131bada6c18c2b6e"
        const brand_id = "6413f1914e55f51a812c580c"
        return service.apiAuth.get(`${resourcePathPlan}/public-list?group_id=${group_id}&brand_id=${brand_id}&is_trial=0`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    }
}